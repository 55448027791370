import React, { Fragment } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import classNames from "classnames"

import Layout from "layout"
import Container from "layout/Container"

import useProofOfPaymentIcon from "./hooks/useProofOfPaymentIcon"
import styles from "./utils/proofOfPayment.module.scss"

const ProofOfPaymentComplete = (props) => {
  const { location } = props
  const data = useProofOfPaymentIcon()
  return (
    <Layout title="Thank you!" seoTitle="Thank you">
      <Container isCentered>
        <div className={classNames("columns", styles["column"])}>
          <div className="column is-4-desktop is-12-mobile">
            <figure className={classNames("image mx-3")}>
              <Img
                fluid={data.email.childImageSharp.fluid}
                alt={`Proof of Payment Complete`}
                className={styles["image"]}
              />
            </figure>
          </div>
          <div className="column">
            <Fragment>
              <p className="mb-1">
                Thank you for submitting your proof of payment.
              </p>
              <p className="mb-1">
                Please check your email for your reference number. For faster processing, please provide your reference number if you have any order-related inquiries.
              </p>
              <p className="mb-1">
                You will receive an email confirming your request within the next working day. MedGrocer will update you when your medicines are ready for delivery.
              </p>
            </Fragment>
          </div>
        </div>
        <center>
          <Link to="/" className="mt-2 button is-primary is-medium">
            Finish
          </Link>
        </center>
      </Container>
    </Layout>
  )
}

export default ProofOfPaymentComplete
